exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contactpage-js": () => import("./../../../src/templates/contactpage.js" /* webpackChunkName: "component---src-templates-contactpage-js" */),
  "component---src-templates-grouppage-js": () => import("./../../../src/templates/grouppage.js" /* webpackChunkName: "component---src-templates-grouppage-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-menupage-js": () => import("./../../../src/templates/menupage.js" /* webpackChunkName: "component---src-templates-menupage-js" */),
  "component---src-templates-privacypage-js": () => import("./../../../src/templates/privacypage.js" /* webpackChunkName: "component---src-templates-privacypage-js" */),
  "component---src-templates-winepage-js": () => import("./../../../src/templates/winepage.js" /* webpackChunkName: "component---src-templates-winepage-js" */)
}

